:root {
  /* Only use CSS vars if not using IE */
  --flash-anim-duration: 10s;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1B1C20;
  background-color: #F6F6F6;
  font-size: 1.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  color: #324755;
}

input, select {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #CECECE;
  color: #1B1C20;
  font-size: 1em;
  border-radius: 4px;
  padding: 6px 15px;
}

.custom-react-select, .custom-react-select input {
  padding: 0px;
}

.custom-react-select:focus, .custom-react-select input:focus {
  outline: none;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

input[type='file'] {
  border: none;
  border-radius: 0px;
  padding: 0px;
}

select {
  border-radius: 4px;
  font-size: 1em;
}

button {
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #CECECE;
  color: #1B1C20;
  font-size: 1em;
  border-radius: 4px;
  padding: 10px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus, select:focus, textarea:focus {
  outline: none !important;
  border: 1px solid #1591BF;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

textarea:focus {
  border-radius: 4px;
}

select:focus {
  border-radius: 4px;
}

p {
  margin-top: 20px;
  margin-bottom: 10px;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

li {
  margin: 8px 0px;
  margin-left: 10px;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.regular-checkbox {
	margin-right: 10px;
}


::placeholder {
  color: #CECECE;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #CECECE;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #CECECE;
}

/* .no-scroll {
  overflow: hidden;
}

.no-scroll tr, .no-scroll td, .no-scroll th, .no-scroll div {
  overflow: hidden;
} */